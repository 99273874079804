import React from "react";
import { Slide } from "react-reveal";
import dog1 from "../../assets/images/content/chart1.png";

const Services = () => {
  return (
    <div>
      <div className="aligned-content-container flex">
        <Slide left>
          <div className="right-aligned-content">
            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Impact Evaluations</h3>
                <p>
                  We unlock the power of data and transform it into meaningful
                  actionable insights.
                </p>
              </div>
            </div>

            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Training</h3>
                <p>
                  We empower your team with the requisite skills and knowledge
                  to leverage research and data effectively.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <div className="spacing"></div>
        <Slide right>
          <div className="left-aligned-content">
            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Research &amp;Data Analytics</h3>
                <p>
                  We research, collect data and turn the data into actionable
                  insights to inform.
                </p>
              </div>
            </div>

            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Survey Design &amp; Management</h3>
                <p>We compose questions that your research needs.</p>
              </div>
            </div>
          </div>
        </Slide>
      </div>
      <div className="section-footer buttons-container flex justify-center">
        <a href="/projects" className="button boldTitle light-blue-bg">
          See Our Projects &#8594;
        </a>
      </div>
    </div>
  );
};

export default Services;
