import React from "react";
import { Slide } from "react-reveal";
import h1 from "../../assets/images/content/smartfarm4.jpg";
import HighlightCard from "../reusables/HighlightCard";

const ProjectsBody = () => {
  return (
    <section className="section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="section-body section-spacing-20">
                <Slide left>
                  <ul className="hightlights-container flex">
                    <HighlightCard
                      img={h1}
                      link="#"
                      title="The Impact of Smart Farming in Uganda"
                    />
                    <HighlightCard
                      img={h1}
                      link="/phfarm"
                      title="The Impact of Smart Farming in Uganda"
                    />
                    <HighlightCard
                      img={h1}
                      link="/phfarm"
                      title="The Impact of Smart Farming in Uganda"
                    />
                    <HighlightCard
                      img={h1}
                      link="/phfarm"
                      title="The Impact of Smart Farming in Uganda"
                    />
                    <HighlightCard
                      img={h1}
                      link="/phfarm"
                      title="The Impact of Smart Farming in Uganda"
                    />
                    <HighlightCard
                      img={h1}
                      link="/phfarm"
                      title="The Impact of Smart Farming in Uganda"
                    />
                  </ul>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsBody;
