import React from "react";
import whatsAppIcon from "../assets/images/icons/whatsapp.svg";

const FloatingButton = () => {
  return (
    <div className="relative">
      <div className="floating-button-container">
        <button className="whatsapp-button button">
          <a href="https://wa.me/256774256670" title="WhatsApp Us">
            <img src={whatsAppIcon} class="icon" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default FloatingButton;
