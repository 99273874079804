import React from "react";
import { Slide } from "react-reveal";
import h1 from "../../assets/images/content/smartfarm2.jpg";
import HighlightCard from "../reusables/HighlightCard";

const OurWorks = () => {
  return (
    <section className="section section-spacing-100 white-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 margin-auto center">
              <p className="section-name boldTitle light-blue">Highlights</p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title blue">Some of Our Works</h2>
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <Slide left>
                <ul className="hightlights-container flex">
                  <HighlightCard
                    img={h1}
                    link="/phfarm"
                    title="The Impact of Smart Farming in Uganda"
                  />
                  <HighlightCard
                    img={h1}
                    link="/phfarm"
                    title="The Impact of Smart Farming in Uganda"
                  />
                  <HighlightCard
                    img={h1}
                    link="/phfarm"
                    title="The Impact of Smart Farming in Uganda"
                  />
                </ul>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurWorks;
