import React from "react";
import { Slide } from "react-reveal";
import Services from "../reusables/Services";

const OurServices = () => {
  return (
    <section className="section section-spacing-100 dog-bg special-blue-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 margin-auto center">
              <p className="section-name boldTitle light-blue">What We do</p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title white">Our Services</h2>

                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-100 no-padding-bottom">
              <Services />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
