import React from "react";
import Servicecard from "../reusables/ServiceCard";
import s1 from "../../assets/images/content/s1.jpeg";
import s2 from "../../assets/images/content/s2.jpeg";
import s3 from "../../assets/images/content/s3.jpeg";
import s4 from "../../assets/images/content/smartfarm.jpg";


const ServicesBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <ul className="services-cards-container">
              <Servicecard title="Training" link="/contact-us" img={s2}>
                <p>
                  This is one of the key services we offer, we aim to empower
                  your team with the requisite skills and knowledge to leverage
                  research and data effectively.{" "}
                </p>
                <p>
                  We offer specialized customized training in Research, Survey
                  methodology &amp; planning, Data Analysis, Statistical
                  Software based on your team’s needs and the industry you
                  operate in.{" "}
                </p>
                <p>
                  Our expert trainers, who are also seasoned data analysts and
                  researchers, guide your team through the complexities of data,
                  making it easy to understand and use.
                </p>
              </Servicecard>
              <Servicecard
                title="Impact Evaluations"
                link="/contact-us"
                img={s3}
              >
                <p>
                  In a world where resources are limited, Impact Evaluations
                  ensure that interventions lead to the desired outcomes and
                  that funds are allocated to the most effective initiatives.
                </p>
                <p>
                  We collaborate with partners to carefully design and implement
                  successful impact evaluations that facilitate the
                  understanding of the effectiveness and efficiency of programs
                  and policies, providing accountability to stakeholders, and
                  generating knowledge about what works and why. At the
                  different stages throughout the program life-cycle, we support
                  organizations to conduct baseline evaluations, mid term
                  reviews and endline evaluations.
                </p>
                <p>
                  The insights gained from these evaluations guide
                  decision-making, improve program design, and contribute to the
                  broader knowledge base in the field.
                </p>
                <p>
                  We are committed to providing this service with the highest
                  level of rigor and integrity.
                </p>
              </Servicecard>
              <Servicecard title="Research" link="/contact-us" img={s1}>
                <p>
                  This is one of the key services we offer, we aim to empower
                  your team with the requisite skills and knowledge to leverage
                  research and data effectively.{" "}
                </p>
                <p>
                  We offer specialized customized training in Research, Survey
                  methodology &amp; planning, Data Analysis, Statistical
                  Software based on your team’s needs and the industry you
                  operate in.{" "}
                </p>
                <p>
                  Our expert trainers, who are also seasoned data analysts and
                  researchers, guide your team through the complexities of data,
                  making it easy to understand and use.
                </p>
              </Servicecard>
              <Servicecard title="Data Analytics" link="/contact-us" img={s3}>
                <p>
                  Our Data Analytics service provides comprehensive solutions
                  for dealing with vast amounts of raw data. Informed by well
                  thought robust data analysis plans, we leverage modern data
                  tools and methodologies to turn your data into actionable
                  insights
                </p>
                <p>
                  Our team of experts applies statistical analysis, predictive
                  modeling, machine learning, and other data analysis techniques
                  to enable you achieve your data goals. We have expertise in
                  top on-demand data and programming software and technologies
                </p>
              </Servicecard>
              <Servicecard
                title="Survey Design and management"
                link="/contact-us"
                img={s4}
              >
                <p>
                  This is one of the key services we offer, we aim to empower
                  your team with the requisite skills and knowledge to leverage
                  research and data effectively.{" "}
                </p>
                <p>
                  We offer specialized customized training in Research, Survey
                  methodology &amp; planning, Data Analysis, Statistical
                  Software based on your team’s needs and the industry you
                  operate in.{" "}
                </p>
                <p>
                  Our expert trainers, who are also seasoned data analysts and
                  researchers, guide your team through the complexities of data,
                  making it easy to understand and use.
                </p>
              </Servicecard>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBody;
