import React from "react";
import { Slide } from "react-reveal";
import d1 from "../../assets/images/logo/elogo.jpeg";
import rate from "../../assets/images/smallimages/rate-biz.svg";

const WhyUs = () => {
  return (
    <section className="section triangles-bg section-spacing-50">
      <div className="section-content">
        <div className="why-container flex">
          <div className="why-text-side">
            <p className="section-name boldTitle light-blue center">
              WHY COMPANIES &amp; ORGANISATIONS
            </p>
            <Slide top>
              <div className="section-head center">
                <h2 className="section-title blue">CHOOSE US</h2>
              </div>
            </Slide>
            <div className="section-body width-600 margin-auto">
              <ul className="why-list-container">
                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>Hands-on experience</h3>
                    <p>
                      Our training programs are designed to provide hands-on
                      experience.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>Customized training</h3>
                    <p>
                      We offer specialized customized training based on your
                      team’s needs and the industry you operate in.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>Seasoned Experts</h3>
                    <p>
                      Well equipped team to guide your team through the
                      complexities of data, making it easy to understand and
                      use.
                    </p>
                  </div>
                </li>

                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>
                      We leverage cutting-edge technologies &amp; methodologies
                    </h3>
                    <p>
                      To deliver high-quality, timely, and actionable insights.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>Highly rated and recommended</h3>
                    <p>
                      Top and leading organizations rate us and recommend us
                      based on our works.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
