import React from "react";
import { Helmet } from "react-helmet";
import ContactBody from "../components/contact/ContactBody";
import PageHero from "../components/reusables/hero/PageHero";

export default function Contact() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.elkacenters.org/contact-us" />
        <meta
          name="description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta
          property="og:description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta property="og:title" content="Contact Us - ELKA CENTER" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707892576/elka-banner_yoytzr.jpg"
        />

        <meta name="twitter:title" content="Contact Us - ELKA CENTER" />
        <meta name="twitter:text:title" content="Contact Us - ELKA CENTER" />
        <meta
          name="twitter:description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707892576/elka-banner_yoytzr.jpg"
        />
        <title>Contact Us - ELKA CENTER</title>
      </Helmet>
      <PageHero
        classes="black-bg"
        titleClasses="title uppercase white text-shadow"
        title="Contact Us &amp; Map"
      />
      <ContactBody />
    </>
  );
}
