import React from 'react';
import { Slide } from "react-reveal";
import bull from "../../assets/images/icons/bull.svg";
import eye from "../../assets/images/icons/eye.svg";

const Mission = () => {
    return (
      <Slide top>
        <div className="mission-side">
          <div className="mission-item">
            <img src={bull} alt="Mission" />
            <div>
              <h2>Mission</h2>
              <p>
                To provide comprehensive and innovative research and data
                solutions that drive informed decision-making and policy that
                impacts livelihoods.
              </p>
            </div>
          </div>
          <div className="mission-item">
            <img src={eye} alt="Vision" />
            <div>
              <h2>Vision</h2>
              <p>
                “Shaping the future of evidence-based decision making and policy
                formulation through rigorous research and advanced data
                analytics.”
              </p>
            </div>
          </div>
        </div>
      </Slide>
    );
}
 
export default Mission;