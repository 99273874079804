import React from "react";
import { Slide } from "react-reveal";
import d10 from "../../assets/images/logo/elogo.jpeg";
import check from "../../assets/images/icons/check.svg";

const AboutHome = () => {
  return (
    <section className="section section-spacing-100 whitedot-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="about-contents relative flex">
              <div className="about-image-side flex">
                <Slide top>
                  <img src={d10} className="big-img about-img" alt="Dog" />
                </Slide>
              </div>
              <div className="about-text-side">
                <p className="section-name boldTitle blue">ABOUT US</p>
                <Slide top>
                  <div className="section-head">
                    <h2 className="section-title light-blue">
                      unlocking the power of data
                    </h2>
                    <p className="section-subtitle margin-top-bottom-10">
                      At ELKA CENTRE, we are dedicated to unlocking the power of
                      data and transforming it into meaningful actionable
                      insights.
                    </p>
                    <p>
                      Besides our experienced team, we leverage cutting-edge
                      technologies and methodologies to deliver high-quality,
                      timely, and actionable insights.{" "}
                    </p>
                  </div>
                </Slide>
                <div className="section-footer buttons-container flex justify-center section-spacing-20 no-padding-bottom">
                  <a href="#" className="button nav-cta boldTitle uppercase">
                    Read More &#8594;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
