import React from "react";
import { Slide } from "react-reveal";
import Mission from "../reusables/Mission";

const AboutBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom cloudy-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="textpage-container">
              <div className="textpage-inner section-spacing-50 no-padding-top flex">
                <div className="textpage-text-side">
                  <h2 className=" light-blue">ELKA CENTER</h2>
                  <div className="textpage-text-body">
                    <p>
                      ELKA CENTRE was founded in Uganda in 2020 as a specialist
                      firm offering core services of{" "}
                      <strong>Data Analysis</strong> and{" "}
                      <strong>Conducting Impact Evaluations</strong>. Over time,
                      we expanded our service scope to incorporate{" "}
                      <strong>Research Training</strong> and{" "}
                      <strong>Survey design and management.</strong>
                    </p>
                    <p>
                      At <strong>ELKA CENTRE</strong>, we are dedicated to
                      unlocking the power of data and transforming it into
                      meaningful actionable insights.
                    </p>
                    <p>
                      Our team of experienced researchers and data scientists
                      have profound experience to provide innovative,
                      data-driven solutions tailored to your research and data
                      needs.
                    </p>
                    <p>
                      We leverage cutting-edge technologies and methodologies to
                      deliver high-quality, timely, and actionable insights.{" "}
                    </p>
                    <h3>Our Vision</h3>
                    <p>
                      “Shaping the future of evidence-based decision making and
                      policy formulation through rigorous research and advanced
                      data analytics.”
                    </p>
                    <h3>Our Mission</h3>
                    <p>
                      To provide comprehensive and innovative research and data
                      solutions that drive informed decision-making and policy
                      that impacts livelihoods.
                    </p>

                    <h3>Location &amp; Contacts</h3>
                    <p>
                      We are located in Kampala Uganda for a visit from Monday
                      to Sunday starting at 8AM to 5PM. You can always give us a
                      call or WhastApp on +256 (774) 256 670 or send us an email
                      info@elkacenters.com.
                    </p>
                  </div>
                  <div className="textpage-text-model boldTitle uppercase">
                    <p>
                      ELKA CENTER, A RESEARCH & DATA ANALYSIS FIRM unlocking the
                      power of data to impact livelihoods.
                    </p>
                  </div>
                </div>
                <Mission />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
