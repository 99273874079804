import React from "react";

const Servicecard = (props) => {
  return (
    <li className="service-card">
      <img src={props.img} alt={props.title} />
      <h3>{props.title}</h3>
      <div className="service-desc">{props.children}</div>
      <a
        href={props.link}
        className="card-cta uppercase boldTitle"
        target="_blank"
        rel="noopener noreferrer"
      >
        Get in touch
      </a>
    </li>
  );
};

export default Servicecard;
