import React from "react";
import h1 from "../../../assets/images/content/smartfarm4.jpg";

const Farm = () => {
  return (
    <section className="section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="section-body section-spacing-10 width-600 margin-auto">
                <div className="highlight-content-container">
                  <h2>The Impact of Smart Farming in Uganda</h2>
                  <figure>
                    <img src={h1} alt="Smart Farm" />
                    <figcaption>Collecting Data on the Firm</figcaption>
                  </figure>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Alias modi rerum eius. Non fugiat facere quis soluta? Cum,
                      explicabo, quos praesentium, laborum aliquam
                      necessitatibus laboriosam aliquid totam aperiam commodi
                      ratione.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Alias modi rerum eius. Non fugiat facere quis soluta? Cum,
                      explicabo, quos praesentium, laborum aliquam
                      necessitatibus laboriosam aliquid totam aperiam commodi
                      ratione.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Alias modi rerum eius. Non fugiat facere quis soluta? Cum,
                      explicabo, quos praesentium, laborum aliquam
                      necessitatibus laboriosam aliquid totam aperiam commodi
                      ratione.
                    </p>
                    <h3>Lorem Ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Alias modi rerum eius. Non fugiat facere quis soluta? Cum,
                      explicabo, quos praesentium, laborum aliquam
                      necessitatibus laboriosam aliquid totam aperiam commodi
                      ratione.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Farm;
