import React from "react";
import { Slide } from "react-reveal";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="hero-inner">
        <Slide top>
          <div className="hero-content-container welcome">
            <div className="hero-content-inner centered">
              <div className="hero-body centered-inner">
                <div className="hero-body-head">
                  <h1 className=" margin-bottom-20"> ELKA CENTER</h1>
                  <p className="boldTitle">
                    A RESEARCH &amp; DATA ANALYSIS FIRM
                  </p>
                  {/*
                  <a className="boldTitle" href="/about">
                    See About Us &#8594;
                  </a>
                  */}
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default HomeHero;
