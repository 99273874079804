import React from "react";
import { Helmet } from "react-helmet";
import Farm from "../components/projects/highlights/farm";
import PageHero from "../components/reusables/hero/PageHero";

export default function PHFarm() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.elkacenters.org/phfarm" />
        <meta
          name="description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta
          property="og:description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta
          property="og:title"
          content="Highlights: The Impact of Smart Farming in Uganda"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707892576/elka-banner_yoytzr.jpg"
        />

        <meta
          name="twitter:title"
          content="Highlights: The Impact of Smart Farming in Uganda"
        />
        <meta
          name="twitter:text:title"
          content="Highlights: The Impact of Smart Farming in Uganda"
        />
        <meta
          name="twitter:description"
          content="Data Analysis and Research Consulting Firm based in Uganda, East Africa"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1707892576/elka-banner_yoytzr.jpg"
        />
        <title>Highlights: The Impact of Smart Farming in Uganda</title>
      </Helmet>
      <PageHero
        classes="black-bg"
        titleClasses="title uppercase white text-shadow"
        title="Highlights: The Impact of Smart Farming in Uganda"
      />
      <Farm />
    </>
  );
}
