import React from "react";
import logo from "../../assets/images/logo/elogo.jpeg";
import map from "../../assets/images/icons/map.svg";
import phone from "../../assets/images/icons/phone.svg";
import email from "../../assets/images/icons/email.svg";
import clock from "../../assets/images/icons/clock.svg";

const Footer = () => {
  return (
    <section className="footer section-spacing-20 no-padding-bottom black-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-50">
              <div className="footer-menu flex">
                <div className="footer-menu-item about-footer">
                  <img src={logo} className=" box-shadow" alt="Logo" />
                  <div className="footer-body">
                    <p>
                      ELKA CENTRE is a research &amp; data analysis, empowering
                      livelihoods through knowledge action. It was founded in
                      Uganda in 2020 as a specialist firm offering core services
                      of Data Analysis and Conducting Impact Evaluations.
                    </p>
                  </div>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Contact Us</h3>
                  <ul className="footer-nav contact-us">
                    <li>
                      <img src={map} alt="Map Icon" />
                      <span>Kampala, Uganda</span>
                    </li>
                    <li>
                      <img src={clock} alt="Clock icon" />
                      <span>Sun-Mon 09:00AM - 04:00PM</span>
                    </li>
                    <li>
                      <img src={email} alt="Email" />
                      <span>info@elkacenters.org</span>
                    </li>
                    <li>
                      <img src={phone} alt="Phone" />
                      <span>+256 (774) 256 670</span>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Quick Links</h3>
                  <ul className="footer-nav">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#">About Us</a>
                    </li>
                    <li>
                      <a href="/#">Our Services</a>
                    </li>

                    <li>
                      <a href="/#">Insights &amp; Projects</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Social Media</h3>
                  <ul className="footer-nav">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/ElkaCenters"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/ElkaCenters"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/elka-centers-ltd/about/"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wa.me/256774256670"
                      >
                        WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright padding-top-20 padding-bottom-8 center white">
              <p>&copy; 2024 ELKA CENTER, All Rights Reserved</p>
            </div>
            <div className="designer section-spacing-10 uppercase">
              <div className="design-inner white center">
                <p className=" rich-small">
                  Website by{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ntuna.com"
                    className="yellow"
                  >
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
